import { authorize } from '../utils/authorization';
import { getAudienceAuthorizations } from 'pages/audience/utils/Authorization';
import {
    getIngestorAuthorizations,
    getOnboardingAuthorizations,
    getConnectionsAuthorizations
} from 'pages/integrations/Authorization';

const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const CC = require('CC');
const MenuLinkModel = require('models/app/MenuLink.model');
const FeatureLinkModel = require('models/app/FeatureLink.model');
const BaseView = require('app/Base.view');
const SinistromMenu = require('./SinistromMenu.view');
const AdminMainNavView = require('pages/admin/TopLevelNavigation.view');
const ErrorNavView = require('./ErrorNav.view');
const ErrorView = require('./Error.view');
const PushAlertView = require('./PushAlert.view');
const pageContainerTemplate = require('./pageContainer.template.html');
const UpsellTemplate = require('./Upsell.template.html');
const key = require('keymaster');
const UserSettingUtil = require('utils/userSetting');
require('styles/common.css');
// Out of sync with the js currently; We don't need the print styles and
// they interfere with our pdf export rendering
require('vendor/bootstrap/3_3_5/bootstrap_noPrint.css');
require('styles/platform.css');
require('styleLib/lotacons.css');

/**
 * Page Container
 */
export default BaseView.extend({
    defaults: {
        skipMenus: false,
        error: undefined,
        // error can be defined as object:
        //     message - displayed to user
        //     event   - GA event fired
        //     details - technical info included in event, logging
        //     intro   - First part of error message, e.g. "Oops!"
        //     alertProperties - gets folded into base view alert properties
        menuScheme: '',
        secondaryNav: undefined // NONE|TOP
    },

    template: _.template(pageContainerTemplate),

    initialize: function(options) {
        const self = this;
        self.modalId = `modal-${self.cid}`;

        _.extend(self, self.defaults, options);

        // Any view can trigger this event so the high-level page
        // can take care of adding it to the DOM
        self.listenTo(CC.Events, 'Page:showModal', _.debounce(self.showModal, 1), self);
        self.listenTo(CC.Events, 'Page:showPanel', _.debounce(self.showPanel, 1), self);
        self.listenTo(CC.Events, 'Page:closeModal', self.closeModal, self);
        self.listenTo(CC.Events, 'Page:showFatalError', self.showFatalError, self);
        self.listenTo(CC.Events, 'Page:showFullPageMessage', self.showUpsell, self);
        self.listenTo(CC.Events, 'Page:PushAlert', self.pushAlert, self);
        self.listenTo(CC.Events, 'Page:sendAlertToPage', self.sendAlertToPage, self);

        self.listenTo(Backbone.history, 'route', function(router, route, params) {
            const routeOptions = _.findWhere(self.routes, { name: route });
            if (routeOptions && routeOptions.title) {
                self.setPageTitle(self.context, `${self.title}: ${routeOptions.title}`);
                if (self.navViewClass && self.navViewOptions.enabled) {
                    self.renderSubView('.page-nav-container', self.navViewClass, self.navViewOptions);
                }
            }
        });

        self.listenTo(CC.Events, 'CurrentClientInfo:Changed', self.render, self);

        // eslint-disable-next-line no-undef
        key('⌘+shift+s, ctrl+shift+s', function() {
            CC.utils.trackEvent('GlobalNav', 'ShortcutKey', 'OpenClientSwitcher');
            CC.Events.trigger('ShortcutKey:AccountSwitch');
        });

        // eslint-disable-next-line no-undef
        key('⌘+k, ctrl+k', function() {
            CC.utils.trackEvent('GlobalNav', 'ShortcutKey', 'FocusOnGlobalSearch');
            CC.Events.trigger('ShortcutKey:FocusOnGlobalSearch');
        });

        // Listen for new scroll events, here we debounce our `storeScroll` function
        document.addEventListener('scroll', _.debounce(this.storeScroll), { passive: true });

        // Update scroll position for first time
        this.storeScroll();
    },

    render: function() {
        const $el = $(this.el);
        const self = this;

        const showNav = self.navViewClass && self.navViewOptions.enabled;
        let secondaryNav = 'NONE';
        if (showNav) {
            secondaryNav = 'TOP';
        }
        const whiteLabelKey = CC.SessionData.clientFlags?.get('whiteLabelKey');

        $el.html(
            self.template({
                modalId: this.modalId,
                app: self.app,
                secondaryNav: self.secondaryNav || secondaryNav,
                title: self.title,
                menuScheme: self.menuScheme,
                displayDensity: UserSettingUtil.getUserSetting('displayDensity'),
                labelClass: whiteLabelKey ? 'white-label' : 'standard-label',
                lotameLogo: CC.getConfigSetting('lotameLogo'),
                logo: CC.utils.getBrandLogo(whiteLabelKey),
                fallbackLogo: CC.getConfigSetting('lotameLogo')
            })
        );
        self.$('#selfContainedLoader').html(self.bouncingLoader);
        $el.addClass(`app-${self.app}`);

        if (self.skipMenus) {
            self.$('#selfContainedLoader').hide();
            return self;
        }

        if (_.isUndefined(self.error)) {
            self.setPageTitle(self.context, self.title);
            self.renderNavigationMenu();

            self.renderPageAlert();

            if (self.navViewClass && self.navViewOptions.enabled) {
                self.renderSubView('.page-nav-container', self.navViewClass, self.navViewOptions);
            }
            CC.getImpersonating(function(isImpersonatingResponse) {
                if (self.hasValue(isImpersonatingResponse)) {
                    CC.setImpersonating(true);
                    self.$('#primaryNavbar').addClass('isImpersonatingNavbar');
                    self.$('#loggedInUser').addClass('isImpersonatingUsername');
                    self.$('#loggedInUser_alt_menu').addClass('isImpersonatingUsername');

                    const userMenu = self.$('#userDropDown');
                    const impersonateMenuItem = userMenu.append(
                        "<li class='divider'></li><li><a id='endImpersonation'>End Impersonation</a></li>"
                    );

                    impersonateMenuItem.find('#endImpersonation').on('click', function(evt) {
                        evt.stopPropagation();
                        CC.stopImpersonating();
                        CC.Events.once('impersonation:changed', function() {
                            CC.utils.trackEvent('GlobalNav', 'NavBar - Clicked', 'ImpersonationEnd');
                            window.location.reload(); //force the browser to "refresh"
                        });
                    });
                } else {
                    CC.setImpersonating(false);
                }
            });
        } else {
            self.handleError(self.error);
        }
    },

    /**
     * Reads out the scroll position and stores it in the data attribute
     * so we can use it in our stylesheets
     * @link https://css-tricks.com/styling-based-on-scroll-position/
     */
    storeScroll: function() {
        document.documentElement.dataset.scroll = window.scrollY;
    },

    //
    // Show a modal on the page
    // @param modal The modal to assign to the DOM
    //
    showModal: function(modal) {
        const self = this;
        if (!_.isUndefined(self.currentModal)) {
            self.currentModal.close({ keep_element: true });
        }
        self.currentModal = modal;
        self.assign(`#${self.modalId}`, modal);
        $(`#${self.modalId}`).on('hidden.bs.modal', function() {
            // Be extra careful and remove this
            $('.modal-backdrop').remove();
            self.currentModal.close({ keep_element: true });
        });
    },

    closeModal: function() {
        const self = this;
        if (!_.isUndefined(self.currentModal)) {
            self.currentModal.closeModal();
        }
    },

    showPanel: function(panelView) {
        panelView.displayMode = 'PANEL';
        this.showModal(panelView);
    },

    handleError: function(error) {
        const self = this;
        let errorView;

        error = _.extend({}, error);
        if (typeof error.details === 'undefined') {
            error.details = error.message;
        }

        if (['RequiredModelsLoadFailed', 'AccessDenied', 'InvalidParameters'].indexOf(error.type) !== -1) {
            self.createAndRenderSubView('#primaryNavbar', SinistromMenu, {
                navPermssions: self.getNavMenuPermissions()
            });

            errorView = new ErrorView({
                el: '.content-container',
                reason: error.details,
                reasons: error.reasons,
                code: error.type === 'AccessDenied' ? 404 : 400
            });
            errorView.render();
        } else {
            self.createAndRenderSubView('#primaryNavbar', ErrorNavView, {});

            errorView = new ErrorView({
                el: '.content-container',
                reason: error.details,
                reasons: error.reasons,
                code: error.type === 'AccessDenied' ? 404 : 400
            });
            errorView.render();
        }

        CC.utils.trackEvent('GLOBAL', error.event || error.type, error.details);
    },

    renderNavigationMenu: function() {
        const self = this;

        const navOpts = _.extend({}, self.navViewOptions, {
            app: self.app,
            navPermssions: self.getNavMenuPermissions(),
            subdomain: self.subdomain
        });
        let navView;
        if (self.app === 'admin') {
            navView = self.createAndRenderSubView('#primaryNavbar', AdminMainNavView, navOpts);
        } else {
            navView = self.createAndRenderSubView('#primaryNavbar', SinistromMenu, navOpts);
        }
        navView.once('nav_rendered', function(activeTab, parentHref) {
            CC.Events.trigger('nav_rendered', activeTab, parentHref);
        });
    },

    //
    // Replace the entire page with an error message
    //
    showFatalError: function(errorMessage, xhr, reasons = []) {
        const self = this;
        self.renderNoData({
            el: '.content-container',
            message: CC.utils.getAjaxErrorMessage(xhr, errorMessage),
            alertType: 'danger',
            reasons: reasons
        });
    },

    showUpsell: function(message) {
        this.$('.content-container').html(_.template(UpsellTemplate)({ message: message }));
    },

    pushAlert: function(properties) {
        this.createAndRenderSubView('#push-alert', PushAlertView, properties);
    },

    getNavMenuPermissions: function() {
        const audienceAuthorizations = getAudienceAuthorizations();
        // Audience Menu
        const audienceMenu = {
            AudienceList: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.hasAudienceList,
                isBeta: false,
                isVisible: true,
                title: 'Manage',
                url: 'audience/find',
                searchTitle: 'Manage Audiences',
                description: 'Your audience control panel. View a list of your audiences.'
            }),
            AudienceBulkExport: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.hasBulkExport,
                isVisible: true,
                title: 'Bulk Activation',
                url: 'audience/bulkExport',
                description: 'Activate multiple audiences in your desired activation channels.'
            }),
            AudienceCreate: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.hasLab,
                isVisible: true,
                title: 'Audience Lab',
                url: 'audience/lab',
                description: 'audiences'
            }),
            AudienceLabels: new MenuLinkModel({
                isAuthorized: true, // VIEW_CLIENT
                isVisible: true,
                title: 'Labels',
                url: 'label/manage',
                description: 'View, Create, Edit, and Delete Audience Labels'
            }),
            AudienceEdit: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canManage
            }),
            AudienceView: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canViewStats
            }),
            AudienceStats: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canViewStats
            }),
            AudienceProfile: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canViewAnalytics
            }),
            AudienceDraftManage: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canManageDrafts,
                isVisible: true
            }),
            LookalikeModelingManage: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canManageLookalikes,
                isVisible: true,
                title: 'Lookalike Audiences',
                url: 'audience/find?perspective=lookalike&expiration_statuses=FUTURE&creation_sources=LOOKALIKE',
                searchTitle: 'Manage Lookalike Audiences'
            })
        };

        const analyticsMenu = {
            AudienceAnalytics: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.hasAnalyticsList,
                isVisible: true,
                title: 'Audiences',
                url: 'audience/analyticsList',
                description: 'Audience Analytics'
            }),

            CampaignAnalytics: new MenuLinkModel({
                isAuthorized: authorize('Campaigns', 'View Campaigns'),
                isBeta: false,
                isVisible: true,
                url: 'insights/campaigns',
                title: 'Campaigns',
                description: 'View and manage existing Insights reports.'
            })
        };

        //Reporting Menu
        const reportingLinks = {
            RptBehaviorTypeStats: new MenuLinkModel({
                isAuthorized: authorize(['Behaviors', 'FirstPartyData'], 'View Behaviors'),
                isBeta: false,
                url: 'behaviors/stats_by_behavior_type',
                title: 'Behavior Type Stats',
                description: 'Behavior Type Stats'
            }),
            RptBehaviorCategorizationSummary: new MenuLinkModel({
                isAuthorized: authorize(['Behaviors', 'FirstPartyData'], 'View Reports'),
                isVisible: true,
                url: 'report/categorized_behaviors',
                title: 'Categorization Summary',
                description: 'See which behaviors are or are not categorized in taxonomies.'
            }),
            RptAudienceContribBehaviorsReport: new MenuLinkModel({
                isAuthorized: authorize(['FirstPartyData', 'Audiences', 'Behaviors'], 'View Reports'),
                isVisible: true,
                url: 'report/contributing_behaviors',
                title: 'Behavior Contribution',
                description:
                    'View the composition of an audience by individual behaviors; determine the contribution of each behavior to audience size.'
            }),
            RptAudienceTrendReport: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canViewStats,
                isVisible: true,
                isBeta: false,
                title: 'Audience Trends',
                url: 'audience/trends',
                description: 'A historical view of audience trending uniques.'
            }),
            RptAudienceUsageSummary: new MenuLinkModel({
                isAuthorized: audienceAuthorizations.canViewStats && authorize('AudienceUsageReporting'),
                title: 'Audience Usage Summary',
                url: 'audience/usageByClient',
                description: 'View usage for all of my audiences.'
            }),
            RptAudiencePubContribReport: new MenuLinkModel({
                isAuthorized: authorize(['FirstPartyData', 'Audiences'], 'View Reports'),
                isVisible: true,
                url: 'report/publisher_contribution',
                title: 'Client Contribution',
                description: 'Who are my Top Publishers for an Audience?'
            }),
            RptCollaborateBehaviorPayouts: new MenuLinkModel({
                isAuthorized: authorize(['LotameCollaborateSeller', 'Behaviors'], 'ManageSellerSettings'),
                url: 'behaviors/payouts',
                title: 'Collaborate Taxonomy Payouts',
                isBeta: false,
                description: 'Showcase and Sell Behaviors via Lotame Collaborate'
            }),
            DataSellerPayouts: new MenuLinkModel({
                isAuthorized:
                    authorize('Audiences', 'View Financials') &&
                    CC.SessionData.clientFlags.canViewAutomatedBillingChannelsPayouts(),
                title: 'Data Seller Payouts',
                url: 'audience/payouts',
                description: 'View audiences sold via Automated Billing Channels'
            }),
            RptTransactionStats: new MenuLinkModel({
                isAuthorized: CC.authorizeByPermission('View Reports'),
                url: 'home/transactionStats',
                title: 'Historical Data',
                isBeta: false,
                description: 'A report for data providers'
            }),
            RptBehaviorDataProviderReport: new MenuLinkModel({
                isAuthorized: authorize(['Data Provider Reporting', 'Behaviors'], 'View Financials'),
                url: 'behaviors/dataProviderReport',
                title: 'Data Provider',
                isBeta: false,
                description: 'A report for data providers'
            })
        };

        const collaborateMenu = {
            BehaviorSettings: new MenuLinkModel({
                isAuthorized: authorize(['LotameCollaborateSeller', 'Behaviors'], 'ManageSellerSettings'),
                url: 'behaviors/sellerSettings',
                title: 'Settings',
                isBeta: false,
                description: 'Showcase and Share Behaviors via Lotame Collaborate'
            }),
            BehaviorPartners: new MenuLinkModel({
                isAuthorized: authorize(['SelfManageCollaboratePartners', 'Behaviors'], 'ManageSellerSettings'),
                url: 'behaviors/partners',
                title: 'Partners',
                isBeta: false,
                description: 'Manage Collaborate Partners'
            }),
            BehaviorShowcase: new MenuLinkModel({
                isAuthorized: authorize(['LotameCollaborateSeller', 'Behaviors'], 'ManageSellerSettings'),
                url: 'behaviors/showcase',
                title: 'Showcase',
                isBeta: false,
                description: 'Showcase and Share Behaviors via Lotame Collaborate'
            }),
            BehaviorSell: new MenuLinkModel({
                isAuthorized: authorize(['LotameCollaborateSeller', 'Behaviors'], 'ManageSellerSettings'),
                url: 'behaviors/share',
                title: 'Share',
                isBeta: false,
                description: 'Showcase and Share Behaviors via Lotame Collaborate'
            })
        };

        const myDataMenu = {
            RuleBuilderCreate: new MenuLinkModel({
                isAuthorized: authorize(['LotameRuleBuilder', 'Behaviors'], 'Manage Hierarchies'),
                isBeta: false,
                isVisible: true,
                url: 'behaviors/ruleBuilder',
                title: 'Create',
                searchTitle: 'Create Rule',
                icon: 'lotacon lotacon-plus',
                type: MenuLinkModel.Types.Button
            }),
            MyDataRuleBuilderOverview: new MenuLinkModel({
                isAuthorized: authorize(['LotameRuleBuilder', 'Behaviors'], 'Manage Hierarchies'),
                isBeta: false,
                isVisible: true,
                url: 'behaviors/ruleBuilderOverview',
                title: 'Manage',
                searchTitle: 'Manage Rules',
                description: 'Manage your Dynamic Behaviors using the Lotame Rule Builder.'
            }),
            MyDataBehaviorCategorize: new MenuLinkModel({
                isAuthorized: authorize(['Behaviors', 'FirstPartyData'], 'View Behaviors'),
                url: 'behaviors/manage',
                title: 'Manage',
                searchTitle: 'Manage Behaviors',
                isBeta: false,
                description: 'Manage behaviors by categorizing and/or ignoring them'
            }),
            MyDataBehaviorCreate: new MenuLinkModel({
                isAuthorized: authorize(['Behaviors', 'FirstPartyData'], 'Manage Hierarchies'),
                isBeta: false,
                url: 'behaviors/create',
                title: 'Create',
                searchTitle: 'Create Behavior',
                description: 'Create a new behavior',
                icon: 'lotacon lotacon-plus',
                type: MenuLinkModel.Types.Button
            }),
            MyDataManageTaxonomies: new MenuLinkModel({
                isAuthorized: authorize('Behaviors', 'Manage Hierarchies'),
                isBeta: false,
                url: authorize('FirstPartyData') ? 'taxonomy/manageCustom' : 'taxonomy/manageDefault',
                title: 'Manage',
                searchTitle: 'Manage Taxonomies',
                description: 'Manage your behavior taxonomies.'
            }),
            MyDataBrowseTaxonomies: new MenuLinkModel({
                isAuthorized: authorize('Behaviors', 'View Behaviors'),
                isBeta: false,
                url: 'behaviors/taxonomies',
                title: 'Browse',
                description: 'Browse behavior taxonomies.'
            }),
            MyDataCampaignsCreate: new MenuLinkModel({
                isAuthorized: authorize('Campaigns', 'Manage Campaigns'),
                isBeta: false,
                isVisible: true,
                url: 'insights/campaign',
                title: 'Create',
                searchTitle: 'Create Campaign',
                icon: 'lotacon lotacon-plus',
                type: MenuLinkModel.Types.Button
            }),
            MyDataCampaignsList: new MenuLinkModel({
                isAuthorized: authorize('Campaigns', 'View Campaigns'),
                isBeta: false,
                isVisible: true,
                url: 'insights/campaigns',
                title: 'Manage',
                searchTitle: 'Manage Campaigns',
                description: 'View and manage existing Insights reports.'
            })
        };

        const ingestorAuths = getIngestorAuthorizations();
        const onboardingAuths = getOnboardingAuthorizations();
        const connectionAuths = getConnectionsAuthorizations();
        const connectionMenu = {
            MyDataIntegrations: new MenuLinkModel({
                isAuthorized: ingestorAuths.canViewFiles,
                url: 'integrations/incomingSummary',
                title: 'Batch File Ingestion',
                isBeta: false,
                description: 'View Batch File Ingestion Jobs'
            }),
            ClientSideActivity: new MenuLinkModel({
                isAuthorized: connectionAuths.canSeeClientSideActivity,
                url: 'integrations/clientSideActivity',
                title: 'Client-Side Activity',
                isBeta: false,
                description: 'View Client-Side Activity'
            }),
            RptAudienceExported: new MenuLinkModel({
                isAuthorized: connectionAuths.canViewExports,
                isVisible: true,
                title: 'Activation Summary',
                url: CC.SessionData.clientFlags.isAutomatedBillingChannelsBuyer()
                    ? 'audience/automatedBillingSummary'
                    : 'audience/exportSummary',
                description: 'View activated audiences by activation channel.',
                isBeta: false
            }),
            OnboardingReport: new MenuLinkModel({
                isAuthorized: onboardingAuths.canViewFiles,
                url: 'onboarding/onboardingReport',
                title: 'Onboarding Report',
                isBeta: true,
                description: 'View summary of onboarding activity.'
            }),
            OnboardingFileManagement: new MenuLinkModel({
                isAuthorized: onboardingAuths.canViewFiles,
                url: 'onboarding/fileManagement',
                title: 'File Management',
                isBeta: true,
                description: 'View and managed your uploaded onboarding files.'
            })
        };

        // Build the features
        const featurePermissions = {
            Audiences: new FeatureLinkModel({
                shouldUpsell: false,
                links: audienceMenu,
                slug: 'audiences',
                title: 'Audiences',
                upsellMessage: 'DMP Only',
                icon: 'lotacon lotacon-menu-audiences'
            }),
            MyData: new FeatureLinkModel({
                shouldUpsell: false,
                links: myDataMenu,
                slug: 'mydata',
                title: 'My Data',
                icon: 'lotacon lotacon-menu-my-data'
            }),
            Collaborate: new FeatureLinkModel({
                shouldUpsell: false,
                links: collaborateMenu,
                slug: 'collaborate',
                title: 'Collaborate',
                icon: 'lotacon lotacon-menu-collaborate'
            }),
            Analytics: new FeatureLinkModel({
                shouldUpsell: false,
                links: analyticsMenu,
                slug: 'analytics',
                title: 'Analytics',
                icon: 'lotacon lotacon-menu-analytics'
            }),
            Reporting: new FeatureLinkModel({
                shouldUpsell: false,
                links: reportingLinks,
                slug: 'reporting',
                title: 'Reporting',
                icon: 'lotacon lotacon-menu-reporting'
            }),
            Connections: new FeatureLinkModel({
                shouldUpsell: false,
                links: connectionMenu,
                slug: 'connections',
                title: 'Connections',
                icon: 'lotacon lotacon-menu-connections'
            })
        };

        return featurePermissions;
    },

    setPageTitle: function(activeTab, providedTitle) {
        const self = this;
        let location = '';

        // Use the provided title in the controller
        if (!_.isEmpty(providedTitle)) {
            location = providedTitle;
        } else {
            // Check first to see if this url is also a link in the main
            // navigation menu and use its title
            const permissions = self.getNavMenuPermissions();
            let allLinks = [];
            _.each(_.values(permissions), function(permisionGroup) {
                allLinks = _.union(allLinks, _.values(permisionGroup.get('links')));
            });
            const adjustedUrl = self.subdomain
                ? CC.getCurrentLocationPath().replace(`/${self.subdomain}/`, '')
                : CC.getCurrentLocationPath().replace('/', '');
            const link = _.filter(allLinks, function(link) {
                return link.get('url') === adjustedUrl;
            });

            if (!_.isUndefined(link[0])) {
                location = link[0].get('title');
            }
        }

        // Use the url as a backup if there was no match in the
        // links above by splitting the path into separate capitalized words
        if (location === '') {
            const pathSections = _.compact(
                CC.getCurrentLocationPath()
                    .replace(/_/g, ' ')
                    .split('/')
                    .slice(0, 3)
            );
            if (pathSections.length > 0) {
                const camelCased = pathSections[pathSections.length - 1].split(/(?=[A-Z])/);
                if (!_.isUndefined(camelCased)) {
                    pathSections.splice(pathSections.length - 1, 1);
                    _.each(camelCased, function(item) {
                        pathSections.push(item);
                    });
                }

                location = pathSections.join(' ').toTitleCase();
            }
        }

        // Set the Browser Tab Title without any portal references
        CC.changeDocumentTitle(location.replace('Portal ', ''));
    },

    /**
     * Store a message in session storage for a particular page
     * @param  {String} pageUrl The page on which to show the message
     * @param  {Object} options The alert options
     * @return void
     */
    sendAlertToPage: function(pageUrl, options) {
        sessionStorage.setItem(`message-${pageUrl}`, JSON.stringify(options));
    },

    /**
     * Retrieve a message from session storage for this page and render it
     * @return void
     */
    renderPageAlert: function() {
        const messageId = `message-${this.context}`;
        const data = sessionStorage.getItem(messageId);
        if (data) {
            try {
                this.pushAlert(JSON.parse(data));
            } catch (error) {
                CC.utils.recordError(`Page Alert Retrieval Failed on ${this.context}`, error);
            }
            sessionStorage.removeItem(messageId);
        }
    }
});
