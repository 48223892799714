define(function(require) {
    const TopLevelNavigationTemplate = require('./TopLevelNavigation.template.html');
    const BaseView = require('app/Base.view');
    const ServiceMenuView = require('app/ServiceMenu.view');
    const CC = require('CC');
    const _ = require('underscore');
    require('./TopLevelNavigation.css');

    const TopLevelNavigationView = BaseView.extend({
        template: _.template(TopLevelNavigationTemplate),

        defaults: {
            currentLink: undefined
        },

        events: {},

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults, options);
            self.handleJSRouteChanges();
        },

        handleJSRouteChanges: function() {
            const self = this;
            self.action = registered_params.id;
            self.listenTo(CC.Events, 'SideNav:RouteChange', function(route) {
                self.action = route.title;
                self.render();
            });
        },

        render: function() {
            const self = this;
            const rawMenu = [
                {
                    name: 'analytics',
                    title: 'Analytics',
                    url: '/admin/analytics',
                    links: [
                        {
                            name: 'clientHealthReport',
                            title: 'Client Health Report'
                        }
                    ]
                },
                {
                    name: 'bulk',
                    title: 'Bulk',
                    url: '/admin/bulk',
                    links: [
                        {
                            name: 'audienceUpdate',
                            title: 'Audience Update'
                        },
                        {
                            name: 'audienceDeactivate',
                            title: 'Audience Deactivate'
                        },
                        {
                            name: 'audienceReactivate',
                            title: 'Audience Reactivate'
                        },
                        {
                            name: 'behaviorRename',
                            title: 'Behavior Rename'
                        },
                        {
                            name: 'behaviorCreate',
                            title: 'Behavior Create'
                        },
                        {
                            name: 'baseAudiences',
                            title: 'Off the Shelf Audiences'
                        },
                        {
                            name: 'securityRole',
                            title: 'Security Roles'
                        }
                    ]
                },
                {
                    name: 'clients',
                    title: 'Clients',
                    url: '/admin/clients',
                    links: [
                        {
                            name: 'create',
                            title: 'Create New Client'
                        },
                        {
                            name: 'list',
                            title: 'List'
                        }
                    ]
                },
                {
                    name: 'financial',
                    title: 'Financials',
                    url: '/admin/financial',
                    links: [
                        {
                            name: 'dataUsage',
                            title: 'Enter Data Usage'
                        },
                        {
                            name: 'dataRevenue',
                            title: 'Add External Revenue'
                        },
                        {
                            name: 'DataRevenueUpload',
                            title: 'Upload Data Providers Financials'
                        },
                        {
                            name: 'DataProviderAdjustments',
                            title: 'Data Provider Adjustments'
                        },
                        {
                            name: 'monthlyBilling',
                            title: 'View Monthly Billing'
                        },
                        {
                            name: 'googleLicensees',
                            title: 'Google Licensees'
                        }
                    ]
                },
                {
                    name: 'users',
                    title: 'Users',
                    url: '/admin/users',
                    links: [
                        {
                            name: 'create',
                            title: 'Create New User'
                        },
                        {
                            name: 'list',
                            title: 'List'
                        }
                    ]
                },
                {
                    name: 'misc',
                    title: 'Misc',
                    url: '/admin/misc',
                    links: [
                        {
                            name: 'builder',
                            title: 'APR Stats Builder'
                        },
                        {
                            name: 'assignVerticals',
                            title: 'Assign Industry Verticals'
                        },
                        {
                            name: 'modelGroupsList',
                            title: 'Precision Demographic Model Groups'
                        },
                        {
                            name: 'modelList',
                            title: 'Precision Demographic Models'
                        },
                        {
                            name: 'rollout',
                            title: 'Feature Rollout'
                        }
                    ]
                }
            ];

            const menus = _.map(rawMenu, menu => {
                const matches = /\/admin\/(\w+)\/(\w+)/.exec(location.pathname);
                if (matches) {
                    menu.isActive = matches.length > 0 && matches[1] === menu.name;
                    if (menu.isActive) {
                        const action = _.findWhere(menu.links, { name: matches[2] });
                        self.setCurrentLink({
                            controller: menu.title,
                            action: action ? action.title : self.action
                        });
                    } else if (matches.length > 0 && !self.currentLink) {
                        this.setCurrentLink({
                            controller: matches[1].toTitleCase(),
                            action: self.action
                        });
                    }
                }
                return menu;
            });

            const whiteLabelKey = CC.SessionData.clientFlags.get('whiteLabelKey');
            const viewOptions = {
                menus: menus,
                currentLink: self.currentLink || {},
                logo: CC.utils.getBrandLogo(whiteLabelKey),
                fallbackLogo: CC.getConfigSetting('lotameLogo'),
                labelClass: whiteLabelKey ? 'white-label' : 'standard-label',
                lotameLogo: CC.getConfigSetting('lotameLogo')
            };

            self.$el.html(this.template(viewOptions));

            self.renderSubView('#serviceMenu', ServiceMenuView, {
                app: self.app
            });

            return self;
        },

        setCurrentLink(currentLink) {
            if (this.breadcrumbViewOptions) {
                this.currentLink = {
                    controller: this.breadcrumbViewOptions.pageContext,
                    link: this.breadcrumbViewOptions.pageContextPath,
                    action: this.breadcrumbViewOptions.pageDescription
                };
            } else {
                this.currentLink = currentLink;
            }
        }
    });

    return TopLevelNavigationView;
});
