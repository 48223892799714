define(function(require) {
    const GlobalSearchTemplate = require('./GlobalSearch.template.html');
    const typeAheadResultsTemplate = require('./globalSearchResults.template.html');
    const typeAheadNoResultsTemplate = require('./typeAheadNoResults.template.html');
    const BaseView = require('app/Base.view');
    const _ = require('underscore');
    const CC = require('CC');
    const { authorize } = require('utils/authorization');
    require('typeAhead');

    const GlobalSearchView = BaseView.extend({
        template: _.template(GlobalSearchTemplate),
        typeAheadResultsTemplate: _.template(typeAheadResultsTemplate),
        typeAheadNoResultsTemplate: _.template(typeAheadNoResultsTemplate),

        defaults: function() {
            return {
                clientId: CC.utils.getCurrentClientId()
            };
        },

        events: {
            'focus #globalSearchBox': 'onGlobalSearchFocus',
            'blur #globalSearchBox': 'onGlobalSearchBlur',
            'click .search-icon': 'onGlobalSearchShortcutClick'
        },

        validation: [
            {
                name: 'navPermissions',
                required: true
            }
        ],

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults(), options);

            self.validateProperties();

            self.searchSources = self.buildSearchSources();
        },

        buildSearchSources: function() {
            const self = this;
            const sources = [
                {
                    name: 'pageSearch',
                    displayKey: 'value',
                    source: self.getPageList(),
                    templates: {
                        header: ['<div class="suggestionsHeader">Pages...</div>'].join('\n'),
                        suggestion: function(data) {
                            if (data.activityType == 'Feature') {
                                return /* template */ `<p class="featureSuggest">Go to: <span class="typeAheadCategory">'
                                    ${data.category}
                                    '</span>`;
                            } else {
                                return _.template(/* template */ `<p class="globalTypeAheadEntitySuggest">Find: 
                                        <span class="typeAheadValue"><%- data.value %></span> in <span class="typeAheadCategory">
                                        <%- data.category %>
                                        </span></p>`)({
                                    data: data
                                });
                            }
                        }
                    }
                }
            ];

            if (self.navPermissions.Audiences.isAuthorized()) {
                sources.push({
                    name: 'audienceSearch',
                    displayKey: 'value',
                    source: self.buildAudienceSearch(),
                    templates: {
                        header: ['<div class="suggestionsHeader">Audiences...</div>'].join('\n'),
                        empty: self.typeAheadNoResultsTemplate(),
                        suggestion: function(data) {
                            if (data.activityType == 'audienceMatch') {
                                const links = [];
                                if (
                                    !data.isPrecisionModel &&
                                    (self.navPermissions.Audiences.get('links').AudienceEdit.isActive() ||
                                        self.navPermissions.Audiences.get('links').AudienceDraftManage.isActive())
                                ) {
                                    links.push({
                                        name: 'Edit',
                                        url: `audience/lab/audience_id/${data.value}`,
                                        icon: 'lotacon lotacon-pencil-line',
                                        class: 'quickSearch',
                                        disabled: data.isSplit,
                                        entity: {
                                            id: data.value,
                                            name: 'audience'
                                        }
                                    });
                                }

                                if (
                                    self.navPermissions.Audiences.get('links').AudienceProfile.isActive() &&
                                    authorize('AudienceAnalyticsAccess')
                                ) {
                                    links.push({
                                        name: 'Summary Dashboard',
                                        url: `audience/dashboard/audience_id/${data.value}`,
                                        icon: 'lotacon lotacon-summary-dashboard',
                                        class: 'quickSearch',
                                        disabled: !data.hasApr || data.isDraft,
                                        entity: {
                                            id: data.value,
                                            name: 'audience'
                                        }
                                    });
                                }
                                if (
                                    !data.isPrecisionModel &&
                                    self.navPermissions.Audiences.get('links').AudienceStats.isActive()
                                ) {
                                    links.push({
                                        name: 'Stats',
                                        url: `audience/stats/audience_id/${data.value}`,
                                        icon: 'lotacon lotacon-bar-chart-line',
                                        class: 'quickSearch',
                                        disabled: data.isDraft,
                                        entity: {
                                            id: data.value,
                                            name: 'audience'
                                        }
                                    });
                                }
                                return self.typeAheadResultsTemplate({
                                    name: data.name,
                                    value: data.value,
                                    links: links,
                                    category: 'Audience',
                                    active: data.active
                                });
                            }
                        },
                        footer: function(data) {
                            return _.template(
                                '<div class="suggestionsFooter"><a href="audience/find?filter=<%- query %>" id="searchMoreAudiences">Search for more audiences</a></div>'
                            )({
                                query: data.query
                            });
                        }
                    }
                });
            }
            if (self.navPermissions.MyData.get('links').MyDataBehaviorCategorize.isActive()) {
                sources.push({
                    name: 'behaviorSearch',
                    displayKey: 'value',
                    source: self.buildBehaviorSearch(),
                    templates: {
                        header: ['<div class="suggestionsHeader">Behaviors...</div>'].join('\n'),
                        empty: self.typeAheadNoResultsTemplate(),
                        footer: function(data) {
                            return _.template(
                                '<div class="suggestionsFooter"><a href="behaviors/manage?filter=<%- query %>&categorizationStatus=all" id="searchMoreBehaviors">Search for more behaviors</a></div>'
                            )({
                                query: data.query
                            });
                        },
                        suggestion: function(data) {
                            const links = [];
                            if (self.navPermissions.Behaviors.get('links').BehaviorStats.isActive()) {
                                links.push({
                                    name: 'Stats',
                                    url: `behaviors/stats/behavior_id/${data.value}`,
                                    icon: 'lotacon lotacon-bar-chart-line',
                                    class: 'quickSearch',
                                    entity: {
                                        id: data.value,
                                        name: 'behavior'
                                    }
                                });
                            }

                            return self.typeAheadResultsTemplate({
                                name: data.name,
                                value: data.value,
                                links: links,
                                active: data.active
                            });
                        }
                    }
                });

                sources.push({
                    name: 'catchAllSearch',
                    displayKey: 'value',
                    source: function findMatches(q, cb) {
                        const matches = [];

                        if (self.navPermissions.MyData.get('links').MyDataCampaignsList.isActive()) {
                            matches.push({
                                value: q,
                                category: 'Campaigns',
                                activityType: 'Search',
                                url: `insights/campaigns?filter=${q}`
                            });
                        }
                        if (self.navPermissions.MyData.get('links').MyDataRuleBuilderOverview.isActive()) {
                            matches.push({
                                value: q,
                                category: 'Rule Builder',
                                activityType: 'Search',
                                url: `behaviors/ruleBuilderOverview?filter=${q}`
                            });
                        }

                        cb(matches);
                    },
                    templates: {
                        header: ['<div class="suggestionsHeader">Other Entities...</div>'].join('\n'),
                        suggestion: function(data) {
                            if (data.activityType == 'Feature') {
                                return `<p class="featureSuggest">Go to: <span class="typeAheadCategory">${data.category}</span></p>`;
                            } else {
                                return _.template(`<p class="globalTypeAheadEntitySuggest">Find: 
                                        <span class="typeAheadValue"><%- data.value %></span> in <span class="typeAheadCategory">
                                        <%- data.category %>
                                        </span></p>`)({
                                    data: data
                                });
                            }
                        }
                    }
                });
            }
            return sources;
        },

        render: function() {
            const self = this;
            const viewOptions = {};

            self.$el.html(this.template(viewOptions));
            self.$('[data-toggle="tooltip"]').tooltip();
            const globalSearch = self.$('#globalSearchBox');
            globalSearch.typeahead(
                {
                    hint: false,
                    highlight: true,
                    minLength: 1
                },
                self.searchSources
            );

            globalSearch.on('typeahead:selected', function(evtObj, suggestionObj, dataSetName) {
                CC.utils.trackEvent(
                    'GlobalNav',
                    'NavBar - GlobalSearch',
                    `${suggestionObj.category}:${suggestionObj.value}`
                );
                window.location.href = suggestionObj.url;
            });

            //
            // Since typeahead does not expose the rendered event AND we need to run the
            // bootstrap tooltip command AFTER the elements get rendered, use the mutation observer
            // to check for changes after the typeahed is opened
            //
            globalSearch.on('typeahead:opened', function(eventObj) {
                const observer = new MutationObserver((mutationList, observer) => {
                    self.$('[data-toggle="tooltip"]').tooltip();
                });
                observer.observe(self.$('.tt-dropdown-menu')[0], {
                    childList: true, // observe direct children
                    subtree: true // and lower descendants too
                });
            });
            this.$('#globalSearchBox').trigger('focus');
            return self;
        },

        getPageList: function() {
            const self = this;

            return function findMatches(q, cb) {
                const matches = [];

                _.each(self.allPossibleFeatureLinks, function(aFeatureLink) {
                    const linkName = aFeatureLink.get('searchTitle') || aFeatureLink.get('title');

                    if (linkName.toLowerCase().indexOf(q.toLowerCase()) != -1) {
                        matches.push({
                            value: linkName,
                            category: linkName,
                            activityType: 'Feature',
                            url: aFeatureLink.get('url')
                        });
                    }
                });

                cb(matches);
            };
        },

        buildAudienceSearch: function() {
            const audienceBloodhound = new Bloodhound({
                datumTokenizer: function(datum) {
                    // eslint-disable-next-line no-undef
                    return Bloodhound.tokenizers.whitespace(datum.value);
                },
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: `${CC.apiUrl}/audiences/quickSearch?searchTerm=%QUERY&build_status=ALL`,
                    filter: function(audiences) {
                        return _.map(audiences, function(audience) {
                            const audienceObj = JSON.parse(audience);
                            const audienceId = audienceObj.audience_id;
                            const isPrecisionModel = audienceObj.creation_source === 'OPTIMIZER';
                            let linkUrl;
                            if (isPrecisionModel) {
                                linkUrl = `/audience/channels/audience_id/${audienceId}`;
                            } else {
                                linkUrl = `audience/stats/audience_id/${audienceId}`;
                            }
                            return {
                                value: audienceId,
                                name: audienceObj.name,
                                hasApr: audienceObj.generate_apr,
                                isDraft: audienceObj.build_status === 'DRAFT',
                                isSplit:
                                    audienceObj.audience_type_id === parseInt(CC.configData.SplitAudienceTypeId, 10),
                                isPrecisionModel: isPrecisionModel,
                                activityType: 'audienceMatch',
                                url: linkUrl,
                                active: true
                            };
                        });
                    }
                }
            });

            audienceBloodhound.initialize();
            return audienceBloodhound.ttAdapter();
        },

        buildBehaviorSearch: function() {
            const behaviorsBloodhound = new Bloodhound({
                datumTokenizer: function(datum) {
                    return Bloodhound.tokenizers.whitespace(datum.value);
                },
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: `${CC.apiUrl}/behaviors/quickSearch/?searchTerm=%QUERY`,
                    filter: function(behaviors) {
                        return _.map(behaviors, function(behavior) {
                            const behaviorObj = JSON.parse(behavior);
                            return {
                                value: behaviorObj.behavior_id,
                                name: behaviorObj.name,
                                description: behaviorObj.description,
                                url: `behaviors/stats/behavior_id/${behaviorObj.behavior_id}`,
                                active: behaviorObj.active
                            };
                        });
                    }
                }
            });
            behaviorsBloodhound.initialize();
            return behaviorsBloodhound.ttAdapter();
        },

        onGlobalSearchBlur: function(e) {
            this.$('#globalSearchBox').typeahead('val', '');
        },

        onGlobalSearchShortcutClick: function() {
            this.$('#globalSearchBox').trigger('focus');
        }
    });

    return GlobalSearchView;
});
