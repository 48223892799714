//  Author:         Andrew Conant
//  Description:    Sets up user session data models and stores as CC globals for re-use by application modules
define(function(require) {
    const CC = require('CC');
    const userInfoModel = require('models/security/userInfo.model');
    const UserOrgsCollection = require('models/security/userOrgs.collection').default;
    const userSettingsCollection = require('models/security/UserSettings.collection');
    const userFeaturesCollection = require('models/security/ClientFeatures.collection');
    const FeaturesCollection = require('models/security/Features.collection');
    const ClientFlagsModel = require('models/client/ClientFlags');
    const ClientCollection = require('models/client/Clients.collection');
    const ClientCapabilities = require('models/client/ClientCapabilities.collection');
    const Client = require('models/client/Client');
    const CurrentClientSwitcherView = require('app/CurrentClientSwitcher.view');
    const ConfirmationModalView = require('app/modals/ConfirmationModal.view');
    const _ = require('underscore');

    const $ = require('jquery');
    require('jqueryCookie');

    const Session = {
        state: registered_params['subdomain'] ? 'customUrl' : 'plainUrl',
        subdomain: registered_params['subdomain']
    };

    Session.stateMachine = {
        plainUrl: {
            navigate: function(event, data) {
                if (CC.SessionData.clientFlags.get('whiteLabelKey')) {
                    Session.redirect(CC.SessionData.clientFlags.get('whiteLabelKey'));
                } else {
                    CC.Events.trigger('session_loaded');
                }
            },
            changeClient: function(event, data) {
                Session.redirect();
            }
        },
        customUrl: {
            navigate: function(event, data) {
                const whiteLabelKey = CC.SessionData.clientFlags.get('whiteLabelKey');
                if (whiteLabelKey === Session.subdomain || Session.subdomain === 'admin') {
                    CC.Events.trigger('session_loaded');
                } else if (data.currentClientId) {
                    // The url hss changed the client
                    Session.redirect();
                } else {
                    // We can't determine which client has that whitelabel key
                    const clients = new ClientCollection(null, {
                        queryParams: {
                            white_label_key: Session.subdomain
                        }
                    });
                    clients.fetch({
                        success: function(collection) {
                            if (collection.length === 1) {
                                // Switch the current client to match the url
                                CC.utils.setCurrentClientInfo(collection.at(0));
                                Session.start();
                            } else if (collection.length > 1) {
                                const modal = new ConfirmationModalView({
                                    modalId: 'switchConfirm',
                                    saveText: 'Yes',
                                    modalTitle: 'Which client do you specifically want?',
                                    showClose: false,
                                    showSave: false,
                                    showCancel: true,
                                    contentView: new CurrentClientSwitcherView({})
                                });
                                modal.setElement('#pageContainer').render();
                            } else {
                                const error = {
                                    message: 'Invalid Url',
                                    details: 'No such account',
                                    type: 'SessionLoadFailed',
                                    reasons: ['Check the url for a proper white label key']
                                };
                                CC.Events.trigger('session_error', error);
                            }
                        },
                        error: function(xhr) {
                            const error = {
                                message: 'Failed to verify white label key',
                                details: 'Client call failed',
                                type: 'SessionLoadFailed',
                                reasons: ['Check your internet connection and retry']
                            };
                            CC.Events.trigger('session_error', error, xhr);
                        }
                    });
                }
            },
            changeClient: function(event, data) {
                if (Session.subdomain === 'admin' && location.pathname.includes('_id')) {
                    const lastSlashIndex = location.href.lastIndexOf('/');

                    location.href = `${location.href.substring(0, lastSlashIndex)}/${data.newCurrentClient.get('id')}`;
                    CC.Events.trigger('session_loaded');
                } else {
                    Session.redirect();
                }
            }
        },
        invalidUrl: {
            navigate: function(event, data) {
                CC.Events.trigger('session_loaded');
            }
        }
    };

    Session.handleEvent = function(event, data) {
        const self = this;

        const nextStep = Session.stateMachine[Session.state][event];
        if (_.isFunction(nextStep)) {
            nextStep.apply(self, [event, data]);
        }
    };

    Session.performLogin = function() {
        if (registered_params['subdomain']) {
            CC.utils.storeWhiteLabelKey(registered_params['subdomain']);
        }
        const params = new URLSearchParams(location.search);
        const idp = params.has('idp') ? params.get('idp') : 'Lotame';
        location.href = `${CC.configData.CCAPIUrl}saml2/authenticate/${idp}`;
    };

    Session.checkLogin = function() {
        // Check for the API's health check image - we have to do this before anything else
        // so that the API has a chance to cache the RelayState parameter, which will tell
        // it where our session should go after login with whatever IdP we end up using.
        $.get(`${CC.apiHealthCheckPixel}?RelayState=${location.href}`, function() {
            // Try to load the current user.
            CC.SessionData.userInfo = new userInfoModel();
            CC.SessionData.userOrgs = new UserOrgsCollection();

            const queryParams = new URLSearchParams(location.search);
            const currentClientId = queryParams.get('currentClientId');

            // Now actually do the fetch.
            CC.utils
                .waitForXhrs(
                    [
                        CC.SessionData.userInfo.fetch({
                            cache: false
                        }),
                        CC.SessionData.userOrgs.fetch({
                            data: {
                                include_stewards: true
                            }
                        })
                    ],
                    () => {
                        if (currentClientId) {
                            // Remove any `currentClientId` params as we don't need it anymore
                            // and can interfere with other attempts to change the client
                            const url = new URL(location);
                            url.searchParams.delete('currentClientId');
                            history.replaceState(null, null, url);

                            const currentClientData = CC.SessionData.userOrgs.get(currentClientId);
                            if (currentClientData) {
                                // Avoid another lookup as the requested client is one to which the user is assigned
                                CC.utils.storeWhiteLabelKey(undefined);
                                CC.utils.setCurrentClientInfo(CC.SessionData.userOrgs.get(currentClientId));
                                CC.Events.trigger('userinfo_loaded', {
                                    currentClientId: currentClientId
                                });
                            } else if (currentClientId > 0) {
                                // Go look up this non-assigned client
                                const client = new Client({ id: currentClientId });
                                client.fetch({
                                    success: () => {
                                        CC.utils.storeWhiteLabelKey(undefined);
                                        CC.utils.setCurrentClientInfo(client);
                                        CC.Events.trigger('userinfo_loaded', {
                                            currentClientId: currentClientId
                                        });
                                    },
                                    error: xhr => {
                                        console.error(CC.utils.getAjaxErrorMessage(xhr, 'Error getting client'));
                                        // If we get something we couldn't parse, like a login discovery menu, then force login.
                                        Session.checkLogin();
                                    }
                                });
                            }
                        } else {
                            CC.Events.trigger('userinfo_loaded');
                        }
                    }
                )
                .fail(xhr => {
                    // Failed to get the user info
                    Session.performLogin();
                });
        }).fail(xhr => {
            // API Image Failed
            Session.performLogin();
        });
    };

    Session.scheduleHealthCheck = function() {
        // If we stay on the same page for more than ten minutes, ping the health check pixel periodically.
        setTimeout(function() {
            // check it -
            $.get(`${CC.apiHealthCheckPixel}?RelayState=${location.href}`, function() {
                // Do nothing in case of success.
            }).fail(xhr => {
                Session.performLogin();
            }); // If we got an error, try to log in.

            // Schedule it to check again.
            Session.scheduleHealthCheck();
        }, 600000);
    };

    Session.start = function(options = { clientChange: false, source: '' }) {
        Session.checkLogin();
        Session.scheduleHealthCheck();

        // series of asynchronous callbacks for user session data
        // loads user orgs model once user info is received
        CC.Events.once('userinfo_loaded', function(data = {}) {
            // We don't want to use the saved values but always get the mocked ones directly from the files
            if (CC.configData.BypassCAS) {
                CC.clearSession();
            }
            const fetchCalls = [];

            const pwdReset = CC.SessionData.userInfo.get('pwdReset');

            if (pwdReset && !window.location.pathname.endsWith(`user/passwordSettings`)) {
                window.location.href = `user/passwordSettings`;
            }
            CC.SessionData.userSettingsV2 = new userSettingsCollection();
            CC.SessionData.userSettingsV2.once('sync', function() {
                CC.Events.trigger('usersettings_v2_loaded');
            });

            CC.SessionData.userFeatures = new userFeaturesCollection(null, {
                pathParams: {
                    client_id: CC.utils.getCurrentClientId()
                }
            });
            CC.SessionData.userFeatures.once('sync', function() {
                CC.Events.trigger('userFeatures_loaded');
            });

            CC.SessionData.clientFlags = new ClientFlagsModel({
                clientId: CC.utils.getCurrentClientId()
            });

            CC.SessionData.capabilities = new ClientCapabilities(null, {
                pathParams: {
                    clientId: CC.utils.getCurrentClientId()
                }
            });

            // The full set of features
            CC.SessionData.allFeatures = new FeaturesCollection();

            fetchCalls.push(CC.SessionData.allFeatures.fetch({}));

            fetchCalls.push(CC.SessionData.capabilities.fetch({}));
            fetchCalls.push(CC.SessionData.clientFlags.fetch({}));

            fetchCalls.push(CC.SessionData.userSettingsV2.fetch({}));

            fetchCalls.push(CC.SessionData.userFeatures.fetch({}));

            // Get the client info, if it's not already an assigned client
            const currentClient = CC.SessionData.userOrgs.get(CC.utils.getCurrentClientId());
            const client = currentClient || new Client({ id: CC.utils.getCurrentClientId() });
            if (!currentClient) {
                fetchCalls.push(client.fetch());
            }

            $.when
                .apply(undefined, fetchCalls)
                .done(function() {
                    CC.SessionData.currentClient = client;
                    const whiteLabelKey = CC.SessionData.clientFlags.get('whiteLabelKey');
                    if (options.source == 'NotFound') {
                        Session.state = 'invalidUrl';
                    }
                    Session.handleEvent(options.clientChange ? 'changeClient' : 'navigate', {
                        whiteLabelKey: whiteLabelKey,
                        currentClientId: data.currentClientId
                    });
                    CC.utils.storeWhiteLabelKey(whiteLabelKey);
                })
                .fail(function onError(xhr) {
                    CC.clearSession();

                    const message = 'We are having trouble communicating with the Lotame Servers.';
                    const detail = 'At least one session load call failed';
                    const error = {
                        message: message,
                        details: detail,
                        type: 'SessionLoadFailed',
                        reasons: [
                            'Your internet connection was interrupted',
                            'Your current client is no longer accessible'
                        ]
                    };
                    CC.Events.trigger('session_error', error, xhr);
                });
        });
    };

    Session.redirect = function(newKey = '') {
        let path = window.location.pathname === '/' ? '' : window.location.pathname;
        const subdomain = registered_params['subdomain'];
        // If there's a subdomain that is a white label key, strip it from the url.
        // "admin" is a special case that should be left alone.
        if (subdomain && subdomain !== 'admin') {
            path = path.replace(`/${registered_params['subdomain']}`, '');
        }
        const deferred = $.Deferred();
        deferred.always(function(whiteLabelKey = '') {
            if (whiteLabelKey === null) {
                path = path.replace('/', '');
            }

            const destination = `${window.location.origin}/${whiteLabelKey || ''}${path}`;
            CC.page.redirect(destination + window.location.search);
        });

        if (newKey == '') {
            const flags = new ClientFlagsModel({
                clientId: CC.utils.getCurrentClientId()
            });
            flags.fetch({
                success: function() {
                    deferred.resolve(flags.get('whiteLabelKey'));
                },
                error: function() {
                    deferred.reject();
                }
            });
        } else {
            deferred.resolve(newKey);
        }
    };

    // This return value is questionable (chaining?) - MBM
    return Session;
});
