define(function(require) {
    const CC = require('CC');
    const _ = require('underscore');
    const $ = require('jquery');
    const BaseRouter = require('./Base.router');
    const Session = require('pages/security/session.controller');
    const Backbone = require('backbone');
    const AuthorizationUtil = require('utils/authorization');
    require('vendor/pendo');
    require('bootstrap');
    const PageContainerView = require('app/pageContainer.view').default;

    //Enables cross-domain api calls
    //This way we can communicate from TomaToma to api.lotame
    $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
        // Save the request options for downstream processes
        jqXHR.originalRequestOptions = originalOptions;

        options.crossDomain = {
            crossDomain: true
        };
        options.xhrFields = {
            withCredentials: true
        };
    });

    const BaseController = function(options) {
        _.bindAll(this, 'constructor', 'start', 'initialize', 'renderBaseView');
        return this.constructor(options);
    };

    _.extend(BaseController.prototype, {
        changeCurrentClient: function(newCurrentClient) {
            if (!this.ignoreClientChange) {
                Session.handleEvent('changeClient', {
                    whiteLabelKey: CC.SessionData.clientFlags.get('whiteLabelKey'),
                    newCurrentClient: newCurrentClient
                });
            }
        },

        constructor: function(options) {
            const self = this;
            options = options || {};

            self.baseView = options.baseView === undefined ? true : options.baseView;
            self.baseViewPath = 'app/pageContainer.view';

            self.requireSessionData = options.requireSessionData === undefined ? true : options.requireSessionData;
            self.routerPath = options.routerPath;
            self.routes = options.routes || [];
            self.context = options.context || '';
            self.title = options.title || '';
            self.navViewClass = options.navViewClass;
            self.navViewOptions = options.navViewOptions;
            self.isAuthorized = options.isAuthorized;
            self.menuName = options.menuName;
            self.secondaryNav = options.secondaryNav;

            self.requiredModels = options.requiredModels;
            self.dependentModels = options.dependentModels || function() {};
            self.initFn = options.initFn || function() {};

            self.onSessionStart = options.onSessionStart || function() {};

            // By default, listen to Client change events
            self.ignoreClientChange = options.ignoreClientChange;

            CC.Events.on('CurrentClientInfo:Changed', newCurrentClient => {
                this.changeCurrentClient(newCurrentClient);
            });

            /**
             * @param {Object} alertOptions
             */
            CC.Events.on('Page:Refresh', alertOptions => {
                CC.Events.trigger('Page:sendAlertToPage', this.context, alertOptions);
                this.start();
            });

            CC.DataStore.register(registered_params, {
                source: 'flight'
            });

            return self;
        },

        /**
         * Clean up the global state started by a controller
         */
        stop: function() {
            if (this.baseView) {
                this.baseView.close();
            }
            // Reset scheme
            CC.pageScheme = 'page-scheme-veteris';
            Backbone.history.stop();
            this.router.prototype.stop();
            CC.Events.off();
        },

        /**
         * Fetch several models/collections and call a function when they are completed
         * @param  {array of models/collections} collections       The entities to be fetched
         * @param  {function} completionFn The function to call when the fetches have completed
         * @return {this}              For chaining
         */
        fetchAndWait: function(collections, completionFn) {
            const self = this;
            const errors = [];
            const onComplete = _.after(collections.length, function(data, textStatus, jqXHR) {
                completionFn.apply(self, [data, textStatus, jqXHR, errors]);
            });
            _.each(collections, function(collection, i) {
                collection
                    .fetch({
                        cache: CC.getConfigSetting('useCache')
                    })
                    .fail(function(xhr, textStatus, errorThrown) {
                        errors.push({
                            xhr: xhr,
                            textStatus: textStatus,
                            errorThrown: errorThrown,
                            collection: collection,
                            i: i
                        });
                    })
                    .always(onComplete); //  data|jqXHR, textStatus, jqXHR|errorThrown
            });

            return self;
        },

        start: function(options = {}) {
            const self = this;
            let initialize;

            // Clean up some event handler
            CC.Events.off('session_loaded');
            CC.Events.off('session_error');
            CC.Events.off('baseview_rendered');

            // wait for session data before loading
            if (self.requireSessionData) {
                initialize = _.wrap(self.initialize, function(initializeFn) {
                    CC.Events.on('session_loaded', function() {
                        self.onSessionStart();
                        if (CC.getConfigSetting('pendoActive', false)) {
                            self.initializePendo();
                        }

                        // Load any required models that are necessary for rendering this controller's views.
                        let requiredModels = self.requiredModels;
                        if (_.isFunction(self.requiredModels)) {
                            requiredModels = self.requiredModels(registered_params);
                        }
                        if (requiredModels && requiredModels.length > 0) {
                            self.fetchAndWait(requiredModels, function(xhr, state, message, errors) {
                                const techReason = _.map(errors, function(error) {
                                    if (error.collection) {
                                        return `${error.collection.url()} - ${error.textStatus}`;
                                    } else if (error.model) {
                                        return `${error.model.url()} - ${error.textStatus}`;
                                    }
                                });
                                // if (state === 'error') {
                                if (techReason.length > 0) {
                                    const error = {
                                        message: `${'We were unable to load at least one required set of data from the Lotame Servers.' +
                                            'Please check your ' +
                                            'Internet connection and refresh the page. If the problem persists, contact ' +
                                            '<a class="menuItem" menuitemkey="Help" target="_blank" ' +
                                            'href="'}${CC.getConfigSetting('HelpUrl')}">Lotame Support</a>.`,
                                        details: `At least one required model failed with message: ${message}`,
                                        type: 'RequiredModelsLoadFailed',
                                        intro: 'Oops!',
                                        reasons: ['The url including any number or entity id'],
                                        techReason: techReason
                                    };
                                    self.renderBaseView(error);
                                    CC.utils.recordError(error.type, error.details, xhr);
                                } else {
                                    const dependentModels = self.dependentModels(registered_params);
                                    if (_.isArray(dependentModels)) {
                                        self.fetchAndWait(dependentModels, function(xhr, state, message, errors) {
                                            const techReason = _.map(errors, function(error) {
                                                if (error.collection) {
                                                    return `${error.collection.url()} - ${error.textStatus}`;
                                                } else if (error.model) {
                                                    return `${error.model.url()} - ${error.textStatus}`;
                                                }
                                            });
                                            // if (state === 'error') {
                                            if (techReason.length > 0) {
                                                const error = {
                                                    message: `${'We were unable to load at least one dependent set of data from the Lotame Servers.' +
                                                        'Please check your ' +
                                                        'Internet connection and refresh the page. If the problem persists, contact ' +
                                                        '<a class="menuItem" menuitemkey="Help" target="_blank" ' +
                                                        'href="'}${CC.getConfigSetting(
                                                        'HelpUrl'
                                                    )}">Lotame Support</a>.`,
                                                    details: `At least one required model failed with message: ${message}`,
                                                    type: 'RequiredModelsLoadFailed',
                                                    intro: 'Oops!',
                                                    reasons: ['The url including any number or entity id'],
                                                    techReason: techReason
                                                };
                                                self.renderBaseView(error);
                                                CC.utils.recordError(error.type, error.details, xhr);
                                            } else {
                                                // All prerequisite models have loaded
                                                initializeFn.call(self);
                                            }
                                        });
                                    } else if (_.isObject(dependentModels)) {
                                        self.renderBaseView(dependentModels);
                                        CC.utils.recordError(dependentModels.type, dependentModels.details, null);
                                    } else {
                                        // No dependent models
                                        initializeFn.call(self);
                                    }
                                }
                            });
                        } else {
                            // No required models
                            initializeFn.call(self);
                        }
                    });

                    CC.Events.on('session_error', function(error, xhr) {
                        self.renderBaseView(error);
                        CC.utils.recordError(error.type, error.details, xhr);
                    });

                    Session.start(options);
                });
            }
            // don't wait for session data if self.requireSessionData is set to false
            else {
                initialize = self.initialize;
            }
            // wait for impersonation status from api before loading
            if (CC.impersonationPossible) {
                initialize = _.wrap(initialize, function(func) {
                    if (CC.killImpersonation) {
                        CC.stopImpersonating();
                    } else {
                        CC.startImpersonating(CC.impersonatingUser);
                    }
                    CC.Events.once('impersonation:changed', function() {
                        func.call(self);
                    });
                });
            }
            return initialize.call(this);
        },
        initialize: function() {
            const self = this;

            let createRouterFn;

            // render baseView if required
            if (self.baseView) {
                // TODO: Add auth check to every controller and change the default to false
                const hasAccess = _.result(self, 'isAuthorized', true);
                if (hasAccess) {
                    // wait for baseView before starting router
                    createRouterFn = _.wrap(self.createRouter, function(func) {
                        CC.Events.once('baseview_rendered', function() {
                            func.call(self);
                        });
                    });

                    self.initFn.call(self);
                    // start the router
                    createRouterFn.call(self);
                    self.renderBaseView();
                } else {
                    self.renderBaseView({
                        type: 'AccessDenied',
                        intro: 'Access Denied!',
                        message: `It looks like <em>${CC.utils
                            .getUserInfo()
                            .get(
                                'username'
                            )}</em> does not have permission to access this page for <strong>${CC.utils.getCurrentClientName()}</strong>.`
                    });
                    return self;
                }
            } else {
                createRouterFn = self.createRouter;

                // start the router
                createRouterFn.call(self);
            }

            return self;
        },

        renderBaseView: function(error) {
            const self = this;

            // clean out any leftover modal bits
            $('.modal-backdrop').remove(); // our current modals

            const params = _.defaults(registered_params, {
                app: 'DMP'
            });

            // Make sure we clean up first (eg. Current Client Changed)
            if (!_.isBoolean(this.baseView) && this.baseView) {
                this.baseView.close({ keep_element: true });
            }
            const viewRoutes = _.isFunction(self.routes) ? self.routes(params) : self.routes;

            // Use the updated ui styling if both the client level feature and the user level setting are enabled
            CC.menuScheme = params.app !== 'admin' ? 'menu-scheme-sinistrom' : '';

            this.baseView = new PageContainerView({
                el: '#pageContainer',
                app: params.app,
                subdomain: registered_params['subdomain'],
                error: error,
                context: self.context,
                title: self.title,
                routes: viewRoutes,
                navViewClass: self.navViewClass,
                menuScheme: CC.menuScheme,
                secondaryNav: self.secondaryNav,
                navViewOptions: _.extend(
                    {
                        enabled: true
                    },
                    _.result(self, 'navViewOptions'),
                    {
                        routes: viewRoutes,
                        context: self.context,
                        title: self.title,
                        app: params.app,
                        menuName: self.menuName,
                        route: Backbone.history.location.pathname.replace(`/${params.app}`, ''),
                        params: registered_params
                    }
                )
            });

            this.baseView.render();
            CC.Events.trigger('baseview_rendered', self.context);

            return this.baseView;
        },

        createRouter: function() {
            const self = this;

            //instantiate router
            if (self.router && !self.ignoreClientChange) {
                self.router.prototype.trigger('RefreshView');
                return self;
            }

            const params = _.defaults(registered_params, {
                app: 'DMP'
            });

            self.router = BaseRouter.extend({});
            const routeList = _.isFunction(self.routes) ? self.routes.call(self, params) : self.routes;
            _.each(routeList, function(route) {
                if (
                    (typeof CC.SessionData.userBfs !== 'undefined' && CC.SessionData.userBfs.has(route.bf)) ||
                    (typeof route.bf == 'undefined' && route.path)
                ) {
                    self.router.prototype.add(route);
                }
            });
            //start router
            const specialSubdomains = ['DMP', 'admin'];
            const subdomain = specialSubdomains.includes(registered_params['app'])
                ? ''
                : `/${registered_params['app']}`;
            self.router.prototype.start(`${subdomain}${self.context}`);
            return self;
        },

        initializePendo: function() {
            const role = CC.utils.getUserMemberClientRole();
            const username = CC.utils.getUserSessionInfo('username');
            // Check for possible test users in non-production environments
            if (
                CC.getConfigSetting('CCEnv') !== 'prod' &&
                (username == 'hudson@lotame.com' ||
                    username.match(
                        /^TEMP[A-Za-z0-9]+-|^TMP[A-Za-z0-9]+-|Test.Casper.[0-9]*|^tmp[A-Za-z0-9]+.*|^TMP([a-z0-9]+-)+[a-z0-9]+.*|^CASPER -.*/
                    ))
            ) {
                // We want to leave Pendo off for these cases.
                return;
            }

            const pendoValues = {
                visitor: {
                    id: username,
                    email: username,
                    role: role.displayName,

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                    first_name: CC.utils.getUserSessionInfo('firstName'),
                    last_name: CC.utils.getUserSessionInfo('lastName')
                },

                account: {
                    id: CC.utils.getUserSessionInfo('memberClientId'),
                    name: CC.utils.getUserSessionInfo('memberOrg')
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                }
            };
            pendo.initialize(pendoValues);
        },

        hasTerrene: function(override = '') {
            let hasTerrene = true;
            if (override) {
                if (override === 'off') {
                    hasTerrene = false;
                }
            }
            return hasTerrene;
        },

        canShowPanoramaIdCountStats: function(params = {}) {
            return AuthorizationUtil.authorizeForPanoramaIdCountStats();
        }
    });

    return BaseController;
});
